.container {
  @media print {
    max-width: 100% !important;
  }
}

.container-body {
  max-width: 768px !important;
  margin: auto;

  @media print {
    max-width: 100% !important;
  }
}
